export default {
  methods: {
    getTimestamp(time) {
      return this.$moment(time, 'YYYY-M-D H:mm:ss').unix()
    },

    getDateRangeByUnixTime(dateRange) {
      const dateRangeRequest = this.$formatDateTime(dateRange).getDateRequest()

      return {
        fromDate: this.getTimestamp(dateRangeRequest.fromDate),
        toDate: this.getTimestamp(dateRangeRequest.toDate)
      }
    }
  }
}
