<template>
  <div>
    <div v-if="localStakingBalance && localStakingBalance.length">
      <balance-report
        programTitle="staking program"
        :balances="localStakingBalance"
        target="program"
        @on-change-report-date="onGetStakingProgramBalance($event)"
      />
    </div>

    <div v-if="localCrowdfundingBalances && localCrowdfundingBalances.length">
      <balance-report
        :balances="localCrowdfundingBalances"
        @on-change-report-date="onGetCrowdfundingProgramBalance($event)"
        target="program"
        programTitle="crowdfunding program"
      />
    </div>

    <div v-if="formatPaymentReport && formatPaymentReport.length">
      <balance-report
        :balances="formatPaymentReport"
        target="payment"
        programTitle="Buy Cryptos"
        @on-change-report-date="onGetReportPaymentOrder($event)"
      />
    </div>

    <admin-balance />

    <loading :open="!isLoaded" />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { SMART_CONTRACT_TYPE } from '@/utils/key-mapping'
import unixTime from '@/mixins/unix-time'
import toggleLoading from '@/mixins/toggle-loading'
import BalanceReport from './-components/BalanceReport'
import AdminBalance from './-components/AdminBalance'

export default {
  data() {
    return {
      localStakingBalance: null,
      localCrowdfundingBalances: null,
      smartContractType: SMART_CONTRACT_TYPE,
      dateContext: {
        startDate: new Date(),
        endDate: new Date()
      }
    }
  },

  mixins: [unixTime, toggleLoading],

  components: {
    BalanceReport,
    AdminBalance
  },

  computed: {
    ...mapState('smartContract', ['usdtSmartContract']),

    ...mapGetters('report', ['formatPaymentReport'])
  },

  methods: {
    ...mapActions('program', ['getProgramBalances']),

    ...mapActions('report', ['getReportPaymentOrder']),

    getReportDateRequest(dateRange) {
      const startDate = this.getTimestamp(this.$formatDateTime(dateRange).getDateRequest().fromDate)
      const endDate = this.getTimestamp(this.$formatDateTime(dateRange).getDateRequest().toDate)
      return {
        startDate,
        endDate
      }
    },

    async onGetReportPaymentOrder(reportDate) {
      this.toggleLoading()
      try {
        await this.getReportPaymentOrder({
          startTime: reportDate.startDate,
          endTime: reportDate.endDate
        })
        this.toggleLoading()
      } catch (e) {
        this.toggleLoading()
        throw e
      }
    },

    async onGetStakingProgramBalance(reportDate) {
      this.toggleLoading()
      try {
        const stakingBalanceV2 = await this.getProgramBalances({
          startDate: reportDate.startDate,
          endDate: reportDate.endDate,
          smartContractType: this.smartContractType.stakeV2
        })

        this.localStakingBalance = [
          {
            ...stakingBalanceV2.data.result.data,
            token: { symbol: 'TOKO' }
          }
        ]
        this.toggleLoading()
      } catch (e) {
        this.toggleLoading()
        throw e
      }
    },

    async onGetCrowdfundingProgramBalance(reportDate) {
      this.toggleLoading()
      try {
        const response = await this.getProgramBalances({
          startDate: reportDate.startDate,
          endDate: reportDate.endDate,
          smartContractType: this.smartContractType.crowdfunding
        })
        this.localCrowdfundingBalances = response.data.result.data
        this.toggleLoading()
      } catch (e) {
        this.toggleLoading()
        throw e
      }
    }
  },

  created() {
    this.onGetStakingProgramBalance(this.getReportDateRequest(this.dateContext))
    this.onGetCrowdfundingProgramBalance(this.getReportDateRequest(this.dateContext))
    this.onGetReportPaymentOrder(this.getReportDateRequest(this.dateContext))
    this.toggleLoading()
  }
}
</script>
