<template>
  <div :class="['modal-wrapper', { active: open }]">
    <span class="modal-overlay"></span>
    <div class="modal" style="background: transparent">
      <div class="modal__content" style="border: 0">
        <img src="@/assets/img_spinner.gif" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loading',

  props: {
    open: {
      type: Boolean,
      default: false
    }
  }
}
</script>
