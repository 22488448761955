export default {
  methods: {
    doCopy(e) {
      e.stopPropagation()

      const textEl = this.closest(e.target, '.clipboard-copy')
      const inputEl = document.createElement('input')

      inputEl.value = textEl.querySelector('.clipboard-copy__text-inner').innerText
      textEl.appendChild(inputEl)
      inputEl.select()

      try {
        const message = document.execCommand('copy') ? 'copied!' : 'failed!'
        textEl.querySelector('.tooltip__text').innerText = message
      } catch (err) {
        textEl.querySelector('.tooltip__text').innerText = 'Oops, unable to copy'
      }

      textEl.removeChild(inputEl)

      setTimeout(() => {
        textEl.querySelector('.tooltip__text').innerText = 'copy text'
      }, 1000)
    },

    closest(el, selector) {
      const matchesSelector = el.matches || el.webkitMatchesSelector || el.mozMatchesSelector || el.msMatchesSelector

      while (el) {
        if (matchesSelector.call(el, selector)) {
          return el
        } else {
          el = el.parentElement
        }
      }

      return null
    }
  }
}
