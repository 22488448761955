<template>
  <nav>
    <ul class="tab mb-16">
      <li
        v-for="item in tabList"
        :key="item.name"
        :class="['tile tile--tab tab__item', { active: item.name === activeTabName }]"
        @click="onActiveTab(item.name)"
      >
        <p class="tile__content text-uppercase">{{ item.title }}</p>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'Tabs',

  data() {
    return {
      activeTabName: ''
    }
  },

  props: {
    tabList: {
      type: Array,
      require: true
    }
  },

  methods: {
    onActiveTab(tabTarget) {
      if (this.activeTabName === tabTarget) return
      this.activeTabName = tabTarget
      this.$emit('active-tab', tabTarget)
    }
  },

  created() {
    this.activeTabName = this.tabList[0].name
  }
}
</script>
