<template>
  <div>
    <div class="d-flex mt-24">
      <h2 class="headline--md text-bold text-uppercase">{{ programTitle }}</h2>
      <date-range-picker
        v-model="dateContext"
        opens="left"
        format="yyyy-mm-dd hh:mm"
        @toggle="isDateRangeOpen = !isDateRangeOpen"
        :class="['bg-gray', { active: isDateRangeOpen }]"
        :append-to-body="true"
      />
    </div>

    <div v-if="target === 'program'">
      <div v-for="(balance, index) in balances" :key="index" class="box box--white box--md mt-16">
        <div class="statistics">
          <statistic
            :title="`${balance.token.symbol} has been staked`"
            :isRating="false"
            :value="balance.totalBalance | numeric_float_number_with_commas"
            valueClass="headline--xl text-purple"
          />

          <statistic
            :title="`Available ${balance.token.symbol} to Withdraw`"
            :isRating="false"
            :value="balance.totalBalanceInPlans | numeric_float_number_with_commas"
            valueClass="headline--xl text-purple"
          />

          <statistic
            title="Available User to Withdraw"
            :isRating="false"
            :value="balance.totalInvestors"
            valueClass="headline--xl text-purple"
          />

          <statistic
            :title="`${balance.token.symbol} To Deposit`"
            :isRating="false"
            :value="balance.totalToDeposit"
            :value-class="depositeClasses"
          />
        </div>

        <div v-if="shouldDeposit(balance)" class="d-flex justify-start mt-32">
          <toko-warning-icon class="mr-8" />
          <p class="text-error">You need to deposit more {{ balance.token.symbol }} for selected date</p>
        </div>
      </div>
    </div>

    <div v-else class="box box--white box--md mt-16">
      <div class="statistics">
        <statistic
          v-for="(balance, index) in balances"
          :key="index"
          :title="balance.title"
          :isRating="false"
          :value="balance.value | numeric_float_number_with_commas"
          valueClass="headline--xl text-purple"
        >
          <template v-slot:subStatistic>
            <span class="text-gray">(Rp {{ balance.valueCurrency | numeric_float_number_with_commas }})</span>
          </template>
        </statistic>
      </div>
    </div>
  </div>
</template>

<script>
import dateFormat from '@/mixins/date-range-format'
import unixTime from '@/mixins/unix-time'
import DateRangePicker from 'vue2-daterange-picker'
import Statistic from '@/components/Statistic'
import TokoWarningIcon from '@/ui/toko-icons/TokoWarning'

export default {
  name: 'BalanceReport',

  data() {
    return {
      dateContext: {
        startDate: new Date(),
        endDate: new Date()
      }
    }
  },

  mixins: [dateFormat, unixTime],

  props: {
    programTitle: {
      type: String,
      default: 'staking program'
    },

    balances: {
      type: Array,
      require: true
    },

    target: {
      type: String,
      default: 'program'
    }
  },

  components: {
    DateRangePicker,
    Statistic,
    TokoWarningIcon
  },

  computed: {
    depositeClasses() {
      const classes = this.isDeposit ? 'headline--xl text-error' : 'headline--xl text-success'
      return classes
    }
  },

  methods: {
    shouldDeposit(balance) {
      return balance.total_to_deposit > 0
    },

    getReportDateRequest(dateRange) {
      const startDate = this.getTimestamp(this.$formatDateTime(dateRange).getDateRequest().fromDate)
      const endDate = this.getTimestamp(this.$formatDateTime(dateRange).getDateRequest().toDate)
      return {
        startDate,
        endDate
      }
    }
  },

  watch: {
    dateContext: {
      handler(newValue) {
        this.$emit('on-change-report-date', this.getReportDateRequest(newValue))
      },
      deep: true
    }
  }
}
</script>
