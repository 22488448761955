<template>
  <span :class="['clipboard-copy__tooltip', tooltipClass]">
    <button @click="$emit('on-copy', $event)" class="btn btn--clear tooltip">
      <toko-copy-icon />
      <span class="tooltip__text auto left">copy text</span>
    </button>
    <router-link
      v-if="isExternal"
      tag="button"
      :to="externalLink"
      @click.native="$event.stopImmediatePropagation()"
      class="btn btn--clear"
    >
      <toko-external-icon />
    </router-link>
  </span>
</template>

<script>
import TokoCopyIcon from '@/ui/toko-icons/TokoCopy'
import TokoExternalIcon from '@/ui/toko-icons/TokoExternal'

export default {
  name: 'CopyClipboard',

  props: {
    isExternal: {
      type: Boolean,
      default: true
    },

    tooltipClass: {
      type: String,
      require: false
    },

    externalLink: {
      type: String,
      require: false
    }
  },

  components: {
    TokoCopyIcon,
    TokoExternalIcon
  }
}
</script>
