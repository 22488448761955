import Loading from '@/components/Loading'

export default {
  data() {
    return {
      loadingTimeout: null,
      isLoaded: false
    }
  },

  components: {
    Loading
  },

  methods: {
    toggleLoading() {
      clearTimeout(this.loadingTimeout)
      if (this.isLoaded) {
        this.isLoaded = !this.isLoaded
      } else {
        this.loadingTimeout = setTimeout(() => {
          this.isLoaded = !this.isLoaded
        }, 240)
      }
    }
  }
}
