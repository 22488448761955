<template>
  <div class="mt-24">
    <h2 class="headline--md text-bold text-uppercase">Admin Balance</h2>

    <tabs :tabList="tabList" class="mt-8" @active-tab="activeTabName = $event" />
    <div v-if="activeTabName === 'AdminBalanceETH'" class="box box--white box--md">
      <table v-if="eAdminReferral" class="table table--unique">
        <thead>
          <tr>
            <th>No.</th>
            <th>Wallet Address</th>
            <th>TOKO Amount</th>
            <th>Ether Amount</th>
            <th>Status</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(referral, index) in eAdminReferral" :key="index">
            <td>{{ ++index }}</td>
            <td class="wr-25 clipboard-copy">
              <p class="clipboard-copy__text">
                <span class="clipboard-copy__text-inner">{{ referral.address }}</span>
                <copy-clipboard
                  @on-copy="doCopy($event)"
                  :external-link="scanTrackerAddressLink(1, referral.address)"
                />
              </p>
            </td>
            <td>{{ referral.eToko | format_big_int }}</td>
            <td>{{ referral.eEther | format_big_int }}</td>
            <td :class="referral.available ? 'text-success' : 'text-error'" class="w-168 text-bold text-uppercase">
              {{ referral.available ? 'available' : 'not available' }}
            </td>
          </tr>
        </tbody>
      </table>

      <p v-else class="text-center">No data</p>
    </div>

    <div v-if="activeTabName === 'AdminBalanceBSC'" class="box box--white box--md">
      <table v-if="bscAdminReferral" class="table table--unique">
        <thead>
          <tr>
            <th>No.</th>
            <th>Wallet Address</th>
            <th>TOKO Amount</th>
            <th>BNB Amount</th>
            <th>Status</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(referral, index) in bscAdminReferral" :key="index">
            <td>{{ ++index }}</td>
            <td class="wr-25 clipboard-copy">
              <p class="clipboard-copy__text">
                <span class="clipboard-copy__text-inner">{{ referral.address }}</span>
                <copy-clipboard
                  @on-copy="doCopy($event)"
                  :external-link="scanTrackerAddressLink(2, referral.address)"
                />
              </p>
            </td>
            <td>{{ referral.bscToko | format_big_int }}</td>
            <td>{{ referral.bscBNB | format_big_int }}</td>
            <td :class="referral.available ? 'text-success' : 'text-error'" class="w-168 text-bold text-uppercase">
              {{ referral.available ? 'available' : 'not available' }}
            </td>
          </tr>
        </tbody>
      </table>

      <p v-else class="text-center">No data</p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import copyClipboard from '@/mixins/copy-clipboard'
import { TNX_NETWORK_TYPES } from '@/utils/key-mapping'
import Tabs from '@/components/Tabs'
import CopyClipboard from '@/components/CopyClipboard'

export default {
  name: 'AdminBalance',

  data() {
    return {
      activeTabName: 'AdminBalanceETH',
      tabList: [
        { id: 1, name: 'AdminBalanceETH', title: 'ETH NETWORK' },
        { id: 2, name: 'AdminBalanceBSC', title: 'BSC NETWORK' }
      ]
    }
  },

  mixins: [copyClipboard],

  components: {
    Tabs,
    CopyClipboard
  },

  computed: {
    ...mapState('setting', ['adminReferral']),

    eAdminReferral() {
      if (!this.adminReferral || this.adminReferral.length <= 0) return []
      return this.adminReferral.filter(a => a.networkType === TNX_NETWORK_TYPES.ethereum)
    },

    bscAdminReferral() {
      if (!this.adminReferral || this.adminReferral.length <= 0) return []
      return this.adminReferral.filter(a => a.networkType === TNX_NETWORK_TYPES.bsc)
    }
  },

  methods: {
    ...mapActions('setting', ['getAdminAmounts']),

    scanTrackerAddressLink(networkType, addr) {
      let scanLink
      if (networkType === TNX_NETWORK_TYPES.ethereum) {
        scanLink = `/etherscan-address/${addr}`
      } else {
        scanLink = `/bsc-address/${addr}`
      }

      return scanLink
    }
  },

  async created() {
    try {
      await this.getAdminAmounts()
    } catch (e) {
      console.log(e)
      throw e
    }
  }
}
</script>
