export default {
  data() {
    return {
      today: this.$moment().format('LL'),
      isDateRangeOpen: false
    }
  },

  methods: {
    dateFormat(classes, date) {
      if (!classes.disabled) {
        const dateFormat = this.$moment(date).format('LL')
        classes.disabled = this.$moment(dateFormat).isAfter(this.today)
      }

      return classes
    }
  }
}
