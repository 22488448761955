<template>
  <div class="statistic">
    <p class="statistic__title" :class="titleClass">{{ title }}</p>
    <h2 :class="valueClass">{{ value }}</h2>
    <div v-if="isRating" :class="['statistic__rating', `${isPositiveRating ? 'up' : 'down'}`]">
      <span class="mr-4" v-if="ratingText > 0"
        >{{ ratingText | float_number_with_commas }}<span v-show="!this.isZeroRating">%</span></span
      >

      <toko-arrow-up-icon v-if="isPositiveRating" />
      <toko-arrow-down-icon v-if="!isZeroRating && !isPositiveRating" />
    </div>

    <slot name="subStatistic" />
  </div>
</template>

<script>
import TokoArrowUpIcon from '@/ui/toko-icons/TokoArrowUp'
import TokoArrowDownIcon from '@/ui/toko-icons/TokoArrowDown'

export default {
  name: 'Statistic',

  props: {
    title: {
      type: String,
      required: true
    },

    titleClass: {
      type: String,
      required: false,
      default: 'text-gray'
    },

    value: {
      type: [Number, String],
      default: 0
    },

    valueClass: {
      type: String,
      default: 'headline--xl text-white'
    },

    rating: {
      type: Number,
      required: false
    },

    isRating: {
      type: Boolean,
      default: true
    }
  },

  components: {
    TokoArrowUpIcon,
    TokoArrowDownIcon
  },

  computed: {
    isPositiveRating() {
      return this.rating > 0
    },

    isZeroRating() {
      return this.rating === 0
    },

    ratingText() {
      return this.isZeroRating ? '' : this.rating
    }
  }
}
</script>
